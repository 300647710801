import { Link } from "react-router-dom";

export default function CallToAction({ title, text, toPage, label }) {
   return (
      <section className="bg-image p-1">
			<div className="container">
				<h2 className="txt-white">{title}</h2>
				<p className="txt-white mb-2">{text}</p>

				<Link to={toPage} className="badge">{label}</Link>
			</div>
      </section>
   );
}