import review1 from '../img/reviews/review1.jpg';
import review2 from '../img/reviews/review2.jpg';
import review3 from '../img/reviews/review3.jpg';
import review4 from '../img/reviews/review4.jpg';
import review5 from '../img/reviews/review5.jpg';
import review6 from '../img/reviews/review6.jpg';


export default [
   {
      img: review3,
      name: 'Jonatan Kawer',
      stars: 5,
		text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
   },
   {
      img: review2,
      name: 'Julien Stark',
      stars: 4,
      text: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea aliquam et sie commodo sed do eiusmod tempor.'
   },
   {
      img: review1,
      name : 'Lorena Doe',
      stars: 5,
      text: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dumoto eiusmod tempor incididunt ut labore et dolore magna aliqua.'
   },
   {
      img: review4,
      name: 'John Smith',
      stars: 3,
		text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
   },
   {
      img: review6,
      name: 'Alex Brown',
      stars: 3,
      text: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea aliquam et sie commodo sed do eiusmod tempor.'
   },
   {
      img: review5,
      name : 'Jessica Loe',
      stars: 5,
      text: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dumoto eiusmod tempor incididunt ut labore et dolore magna aliqua.'
   },
];