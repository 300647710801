import './HomeNav.css';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import useVisible from '../hooks/useVisible';
import logo from '../img/logo.svg';

export default function HomeNav() {
   const mainNav = useRef();

   const handleMouseMove = e => {
      const container = mainNav.current,
            scrollSpeed = .8;

      if (container) {
         const mouseX = e.clientX,
               containerRect = container.getBoundingClientRect(),
               containerCenterX = containerRect.left + containerRect.width / 2,
               distanceFromCenter = mouseX - containerCenterX;
         container.scrollLeft += distanceFromCenter * scrollSpeed;
      }
   };
   
   const visible = useVisible(15);

   return (
      <>
         <Helmet>
				<title>Sled Florist Demo &bull; React Multipurpose Template</title>
			</Helmet>

         <motion.header
            className="logo"

            initial={{ opacity: 0, y: '100%' }}
				animate={visible ? {opacity: 0, y: -25} : {opacity: 1, y: 0} }
				transition={{ duration: .3, ease: 'easeInOut' }}
         >
            <div className="top-shadow" />
            <img src={logo} alt="Sled Logo" />
         </motion.header>

         <motion.main
            className="slider"
            ref={mainNav}
            onMouseMove={handleMouseMove}

            initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .6, ease: 'easeInOut' }}
         >
            <nav id="navbar">
               <ul>
                  <li>
                     <Link to='/shop'>
                        <section>
                           <h1>Our Flower Shop</h1>
                           <p className="badge">Who we are and why we do it</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('shop')} />
                           <p>Our Story</p>
								</footer>
                     </Link>
                  </li>

                  <li>
                     <Link to='/flowers'>
                        <section>
                           <h1>Our Flowers</h1>
                           <p className="badge">A wide variety of selected flowers</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('fan')} />
                           <p>Our Products</p>
								</footer>
                     </Link>
                  </li>

                  <li>
                     <Link to='/testimonials'>
                        <section>
                           <h1>They Say About Us</h1>
                           <p className="badge">How people found themselves with us</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('quote-left')} />
                           <p>Our Testimonials</p>
                        </footer>
                     </Link>
                  </li>

                  <li>
                     <Link to='/contact'>
                        <section>
                           <h1>Contact Us</h1>
                           <p className="badge">Don't know which flower to choose?</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('comment-dots')} />
                           <p>Get in touch!</p>
								</footer>
                     </Link>
                  </li>
               </ul>
            </nav>
         </motion.main>
      </>
   );
}