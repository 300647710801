import './Contact.css';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import HeaderPage from '../components/HeaderPage';
import ContactForm from '../components/ContactForm';
import CallToAction from '../components/CallToAction';
import Footer from '../components/Footer';
import bg from '../img/contact.webp';

export default function Contact() {
   return (
      <>
         <Helmet>
				<title>Contact Us &bull; Sled Florist Demo &bull; React Multipurpose Template</title>
			</Helmet>

			<HeaderPage
				bg={bg}
				pageName={'Contact Us'}
				subtitle={'Don\'t know which flower to choose?'}
				icon={<FontAwesomeIcon icon={solid('comment-dots')} className="badge-icon" />}
			/>

			<iframe
				className="map"
				title="Maps"
				src="https://www.google.com/maps?q=Venice+Italy&t=&z=8&ie=UTF8&iwloc=&output=embed"

				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
			/>

			<motion.main
            className="container"

				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 1, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
         >
				<section>
					<h2 className="txt-center">Get in Touch!</h2>
					<h3 className="subtitle txt-center mb-2">We will reply to you as soon as possible</h3>

               <div className="grid gtc-2-1">
                  <ContactForm />

                  <ul className="contact-info">
                     <li className="card">
                        <FontAwesomeIcon icon={solid('location-dot')} />
                        <a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=sledreact_landing_travolgi&utm_source=travolgi&utm_medium=referral&aff=travolgi" target="_blank" rel="noopener noreferrer">
								<div>
									<p className='txt-black'>Where we are:</p>
									<address>Your Address 1, Zip - City</address>
								</div>
                        </a>
                     </li>
                     <li className="card">
                        <FontAwesomeIcon icon={solid('phone')} />
								<div>
									<p className='txt-black'>Phone:</p>
									<a href="tel:">+39 321 123456</a>
								</div>
                     </li>             
                     <li className="card">
                        <FontAwesomeIcon icon={solid('envelope')} />
								<div>
									<p className='txt-black'>Email:</p>
									<a href="mailto:">youremail@address.com</a>
								</div>
                     </li>
                     <li className="card">
							<p>Follow:</p>
							<div className='flex'>
                        <a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=sledreact_landing_travolgi&utm_source=travolgi&utm_medium=referral&aff=travolgi" target="_blank" rel="noopener noreferrer">
                           <FontAwesomeIcon icon={brands('facebook-f')} />
                        </a>
                        <a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=sledreact_landing_travolgi&utm_source=travolgi&utm_medium=referral&aff=travolgi" target="_blank" rel="noopener noreferrer">
                           <FontAwesomeIcon icon={brands('instagram')} />
                        </a>
                        <a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=sledreact_landing_travolgi&utm_source=travolgi&utm_medium=referral&aff=travolgi" target="_blank" rel="noopener noreferrer">
                           <FontAwesomeIcon icon={brands('x-twitter')} />
                        </a>
								</div>
                     </li>
                  </ul>
               </div>
            </section>
			</motion.main>

			<CallToAction
				title='Discover our best flowers'
				text='Create the perfect flower box for your anniversary or for your event.'
				toPage='flowers'
				label='Our Products'

				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
			/>

			<Footer />
      </>
   );
}