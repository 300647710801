import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import reviews from '../data/reviews';
import faqs from '../data/faqs';
import HeaderPage from '../components/HeaderPage';
import Review from '../components/Review';
import Faq from '../components/Faq';
import CallToAction from '../components/CallToAction';
import Footer from '../components/Footer';
import bg from '../img/testimonial.webp';


export default function Testimonials() {
	return (
		<>
			<Helmet>
				<title>They Say About Us &bull; Sled Florist Demo &bull; React Multipurpose Template</title>
			</Helmet>

			<HeaderPage
				bg={bg}
				pageName={'They Say About Us'}
				subtitle={'How people found themselves with us'}
				icon={<FontAwesomeIcon icon={solid('quote-left')} className="badge-icon" />}
			/>

			<motion.main
				className="container"

				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
			>
				<section className="mb-2">
					<h2 className="txt-center">Our Testimonials</h2>
					<h3 className="subtitle txt-center mb-2">People love our flowers</h3>

					<div className="grid mb-2">
						{reviews.map((review, idx) =>
							<Review
								key={idx}
								img={review.img}
								fullName={review.name}
								stars={review.stars}
								review={review.text}
							/>
						)}
					</div>
				</section>

				<div className='mb-2'>
					<CallToAction
						title={'What are you waiting for?'}
						text={'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'}
						toPage={'/contact'}
						label={'Contact Now'}
					/>
				</div>

				<section>
					<h2 className="txt-center mb-2">Faqs</h2>

					{faqs.map((faq, idx) =>
						<Faq
							key={idx}
							question={faq.question}
							answer={faq.answer}
						/>
					)}
				</section>				
			</motion.main>

			<Footer />
		</>
	);
}