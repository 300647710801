import photo1 from '../img/gallery/1.webp';
import photo2 from '../img/gallery/2.webp';
import photo3 from '../img/gallery/3.webp';
import photo4 from '../img/gallery/4.webp';
import photo5 from '../img/gallery/5.webp';
import photo6 from '../img/gallery/6.webp';
import photo7 from '../img/gallery/7.webp';
import photo8 from '../img/gallery/8.webp';
import photo9 from '../img/gallery/9.webp';


export default [
   {
      src: photo7,
      alt: 'Tulips'
   },
   {
      src: photo8,
      alt: 'White roses'
   },
   {
      src: photo5,
      alt: 'Pink roses'
   },
   {
      src: photo1,
      alt: 'Big Flower'
   },
   {
      src: photo6,
      alt: 'Bouquet'
   },
   {
      src: photo2,
      alt: 'Daisies'
   },
   {
      src: photo4,
      alt: 'Rare Roses'
   },
   {
      src: photo3,
      alt: 'Special Daisies'
   },
   {
      src: photo9,
      alt: 'House deck'
   }
];
