import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import faqs from '../data/faqs';
import HeaderPage from '../components/HeaderPage';
import Faq from '../components/Faq';
import Footer from '../components/Footer';
import bg from '../img/shop.webp';
import about from '../img/about.webp';
import experience from '../img/experience.webp';

export default function Shop() {
	return (
		<>
			<Helmet>
				<title>Our Flower Shop &bull; Sled Florist Demo &bull; React Multipurpose Template</title>
			</Helmet>

			<HeaderPage
				bg={bg}
				pageName={'Our Flower Shop'}
				subtitle={'Who we are and why we do it'}
				icon={<FontAwesomeIcon icon={solid('shop')} className="badge-icon" />}
			/>

			<motion.main
				className="container"

				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
			>
				<section className="mb-2">
					<h2 className="txt-center">Our Story</h2>
					<h3 className="subtitle txt-center mb-2">Where it all started</h3>

					<div className="grid">
						<article>
							<h2 className="mb-2">About Us</h2>
							<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit.</p>
							<p className="mb-2">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.</p>
							<a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=sled_react_landing_travolgi&utm_source=travolgi&utm_medium=referral&aff=travolgi" className="badge">Contact us</a>
						</article>

						<img src={about} alt="" />
					</div>
				</section>

				<section className="grid gtc-x3 txt-center bg-secondary txt-white mb-2">
					<article>
						<i className="badge-icon mb-2">
							<FontAwesomeIcon icon={solid('plant-wilt')} />
						</i>
						<h2 className="txt-white">Special flowers</h2>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
					</article>

					<article>
						<i className="badge-icon mb-2">
							<FontAwesomeIcon icon={solid('clover')} />
						</i>
						<h2 className="txt-white">Leaf decor</h2>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
					</article>

					<article>
						<i className="badge-icon mb-2">
							<FontAwesomeIcon icon={solid('tree')} />
						</i>
						<h2 className="txt-white">Natural plant</h2>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
					</article>
				</section>

				<section className="mb-2 grid">
					<img src={experience} alt="" />

					<article>
						<h2>Our Experience</h2>
						<h3 className="subtitle mb-2">Since 1999</h3>
						<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua quis nostrud exercitation ullamco laborisdo.</p>
						<ul className='mb-2'>
							<li className='card card-sm'>
								<FontAwesomeIcon icon={solid('check')} />
								<h3>Dolorem macenate</h3>
							</li>
							<li className='card card-sm'>
								<FontAwesomeIcon icon={solid('check')} />
								<h3>Sed aliqua macenas</h3>
							</li>
							<li className='card card-sm'>
								<FontAwesomeIcon icon={solid('check')} />
								<h3>Macenate dolorim</h3>
							</li>
							<li className='card card-sm'>
								<FontAwesomeIcon icon={solid('check')} />
								<h3>Amet consecutem</h3>
							</li>
						</ul>

						<a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=sled_react_landing_travolgi&utm_source=travolgi&utm_medium=referral&aff=travolgi" className="badge">Contact us</a>
					</article>
				</section>

				<section>
					<h2 className="txt-center mb-2">Faqs</h2>

					{faqs.map((faq, idx) =>
						<Faq
							key={idx}
							question={faq.question}
							answer={faq.answer}
						/>
					)}
				</section>
			</motion.main>

			<Footer />
		</>
	);
}