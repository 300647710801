import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import gallery from '../data/gallery';
import HeaderPage from '../components/HeaderPage';
import Gallery from '../components/Gallery';
import Footer from '../components/Footer';
import bg from '../img/flowers.webp';
import florist from '../img/florist.webp';

export default function Flowers() {
	return (
		<>
			<Helmet>
				<title>Our Flowers &bull; Sled Florist Demo &bull; React Multipurpose Template</title>
			</Helmet>

			<HeaderPage
				bg={bg}
				pageName={'Our Flowers'}
				subtitle={'A wide variety of selected flowers'}
				icon={<FontAwesomeIcon icon={solid('fan')} className="badge-icon" />}
			/>

			<motion.main
				className="container"

				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
			>
				<section className="txt-center">
					<h2>Our Products</h2>
					<h3 className="subtitle mb-2">quality and fresh flowers</h3>

					<div className="grid">
						<article>
							<h2 className="mb-2">Florist expert</h2>
							<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
							<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
							<a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=sled_react_landing_travolgi&utm_source=travolgi&utm_medium=referral&aff=travolgi" className="badge">Shop now</a>
						</article>

						<img src={florist} alt="florist" />
					</div>
				</section>
			</motion.main>

			<Gallery imgs={gallery} />

			<Footer />
		</>
	);
}